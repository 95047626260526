import SmoothScroll from "smooth-scroll";

var scroll = new SmoothScroll('a[href*="#"]', {
  speed: 500,
  speedAsDuration: true,
});

// コンサルタントのスライド
function consultantSlide() {
  const slider = document.querySelector('.consultant__slider');
  const sliderArrowRight = document.querySelector(".consultant__arrow-right");
  const sliderArrowLeft = document.querySelector(".consultant__arrow-left");

  const splide = new Splide(slider, {
    type: "loop",
    arrows: false,
    gap: '2rem',
  });

  splide.mount();

  sliderArrowLeft.addEventListener("click", function () {
    splide.go("-1");
  });

  sliderArrowRight.addEventListener("click", function () {
    splide.go("+1");
  });
}

function faq() {
  const faqHeader = document.querySelectorAll('.faq__list .heading');
  const faqContent = document.querySelectorAll('.faq__list .text-wrapper');
  const faqText = document.querySelectorAll('.faq__list .text-wrapper p');

  faqHeader.forEach((elm, index) => {
    elm.addEventListener("click", () => {
      if (!elm.classList.contains('active')) {
        const contentHeight = faqText[index].scrollHeight;

        elm.classList.add('active');
        faqContent[index].style.height = `${contentHeight}px`;
      } else {
        elm.classList.remove('active');
        faqContent[index].style.height = 0;
      }
    });
  });
}


function storySlide() {
  const slider = document.querySelector(".story__slider");
  const sliderArrowRight = document.querySelector(".story__arrow-right");
  const sliderArrowLeft = document.querySelector(".story__arrow-left");

  const splide = new Splide(slider, {
    type: "loop",
    arrows: false,
    gap: '2rem',
  });

  sliderArrowLeft.addEventListener("click", function () {
    splide.go("-1");
  });

  sliderArrowRight.addEventListener("click", function () {
    splide.go("+1");
  });


  splide.mount();
}

// ステップフォームの処理
function stepFormControl() {
  // フォームの進捗表示
  const formProgress01 = document.querySelector(".progress-step-01");
  const formProgress02 = document.querySelector(".progress-step-02");
  const formProgress03 = document.querySelector(".progress-step-03");
  // フォームの画面
  const formStep01 = document.querySelector(".stepform__input-screen--step-01");
  const formStep02 = document.querySelector(".stepform__input-screen--step-02");
  const formStep03 = document.querySelector(".stepform__input-screen--step-03");
  const formStep04 = document.querySelector(".stepform__input-screen--step-04");
  // 次へボタンの取得
  const formButtonNext01 = document.querySelector(".stepform__next--01");
  const formButtonNext02 = document.querySelector(".stepform__next--02");
  const formButtonNext03 = document.querySelector(".stepform__next--03");
  // 送信ボタン
  const formButtonSend = document.querySelector(".button-next--step-send");
  // 戻るボタンの取得
  const formButtonReverse02 = document.querySelector(".stepform__reverse--02");
  const formButtonReverse03 = document.querySelector(".stepform__reverse--03");
  const formButtonReverse04 = document.querySelector(".stepform__reverse--04");
  // フォームのvalue取得
  const formPrefectureStep = document.getElementsByName("form_prefecture_step")[0]; // 都道府県
  const formYearStep = document.querySelector("select.year-step"); // 年
  const formMonthStep = document.querySelector("select.month-step"); // 月
  const formDayStep = document.querySelector("select.day-step"); // 日
  const formNameStep = document.getElementsByName("form_name_step")[0]; // お名前
  const formSexStep = () => {
    let value = "";
    const radiobutton = Array.from(document.getElementsByName("form_sex_step"));

    radiobutton.forEach((elm) => {
      if (elm.checked) {
        value = elm.value;
      }
    });

    return value;
  }; // 性別
  const formTel = document.getElementsByName("form_tel_step")[0]; // 電話番号01
  const formMailStep = document.getElementsByName("form_email_step")[0]; // メールアドレス
  // エラーメッセージの管理
  const errorPrefecture = document.querySelector(".form-error--prefecture-step");
  const errorSex = document.querySelector(".form-error--sex-step");
  const errorName = document.querySelector(".form-error--name-step");
  const errorAge = document.querySelector(".form-error--age-step");
  const errorTel = document.querySelector(".form-error--tel-step");
  const errorEmail = document.querySelector(".form-error--mail-step");
  // 確認画面の入力
  const formConfirmPrefectureStep = document.getElementById("form-confirm-prefecture-step");
  const formConfirmSexStep = document.getElementById("form-confirm-sex-step");
  const formConfirmNameStep = document.getElementById("form-confirm-name-step");
  const formConfirmAgeStep = document.getElementById("form-confirm-age-step");
  const formConfirmTelStep = document.getElementById("form-confirm-tel-step");
  const formConfirmMailStep = document.getElementById("form-confirm-mail-step");

  // 生年月日のフォームに年月日を入れる
  const insertYear = () => {
    let date = new Date;
    let startYear = 1970;
    let endYear = date.getFullYear();

    for (let year = startYear; year <= endYear; year++) {
      const option = `<option value="${year}">${year}</option>`;

      formYearStep.insertAdjacentHTML('beforeend', option);
    }
  }

  const insertMonth = () => {
    for (let month = 1; month <= 12; month++) {
      const option = `<option value="${month}">${month}</option>`;

      formMonthStep.insertAdjacentHTML('beforeend', option);
    }
  }

  const insertDay = () => {
    for (let day = 1; day <= 31; day++) {
      const option = `<option value="${day}">${day}</option>`;

      formDayStep.insertAdjacentHTML('beforeend', option);
    }
  }

  insertYear();
  insertMonth();
  insertDay();

  // ステップ1の「次へ」処理
  formButtonNext01.addEventListener("click", () => {
    let prefectureCheck = false;
    let sexCheck = false;

    // 都道府県の入力確認
    if (formPrefectureStep.value != "") {
      errorPrefecture.classList.remove("active");
      formConfirmPrefectureStep.innerHTML = formPrefectureStep.value;
      prefectureCheck = true;
    } else {
      errorPrefecture.classList.add("active");
      prefectureCheck = false;
    }

    // 性別の入力
    if (formSexStep() != "") {
      errorSex.classList.remove("active");
      formConfirmSexStep.innerHTML = formSexStep();
      sexCheck = true;
    } else {
      errorSex.classList.add("active");
      sexCheck = false;
    }

    // 次に進むの処理
    if (prefectureCheck && sexCheck) {
      formStep01.classList.remove("active");
      formStep02.classList.add("active");
      formProgress01.classList.remove("active");
      formProgress02.classList.add("active");
    } else {
      return;
    }
  });

  // ステップ2の「次へ」処理
  formButtonNext02.addEventListener("click", () => {
    let nameCheck = false;
    let ageCheck = false;

    // 名前の入力
    if (formNameStep.value != "") {
      errorName.classList.remove("active");
      formConfirmNameStep.innerHTML = formNameStep.value;
      nameCheck = true;
    } else {
      errorName.classList.add("active");
      nameCheck = false;
    }

    // 年齢の入力確認
    if (formYearStep.value != '-' && formMonthStep.value != '-' && formDayStep.value != '-') {
      errorAge.classList.remove("active");
      formConfirmAgeStep.innerHTML = `${formYearStep.value}年${formMonthStep.value}月${formDayStep.value}日`;
      ageCheck = true;
    } else {
      errorAge.classList.add("active");
      ageCheck = false;
    }

    // 次に進むの処理
    if (nameCheck && ageCheck) {
      formStep02.classList.remove("active");
      formStep03.classList.add("active");
      formProgress02.classList.remove("active");
      formProgress03.classList.add("active");
    } else {
      return;
    }
  });

  // ステップ3の「次へ」処理
  formButtonNext03.addEventListener("click", () => {
    let telCheck = false;
    let mailCheck = false;

    // メールアドレスの正規表現
    const regExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // 電話の入力
    if (formTel.value != "") {
      errorTel.classList.remove("active");
      formConfirmTelStep.innerHTML = formTel.value;
      telCheck = true;
    } else {
      errorTel.classList.add("active");
      telCheck = false;
    }

    // メールアドレスのバリデーション
    if (formMailStep.value != "" && regExp.test(formMailStep.value)) {
      errorEmail.classList.remove("active");
      formConfirmMailStep.innerText = formMailStep.value;
      mailCheck = true;
    } else if (!regExp.test(formMailStep.value) && formMailStep.value != "") {
      errorEmail.innerHTML = "正しいメールアドレスを入力してください";
      errorEmail.classList.add("active");
      mailCheck = false;
    } else {
      errorEmail.innerHTML = "入力してください";
      errorEmail.classList.add("active");
      mailCheck = false;
    }

    // 次に進むの処理
    if (telCheck && mailCheck) {
      formStep03.classList.remove("active");
      formStep04.classList.add("active");
    } else {
      return;
    }
  });

  // ステップ2の「戻る」の処理
  formButtonReverse02.addEventListener("click", () => {
    formStep02.classList.remove("active");
    formStep01.classList.add("active");
    formProgress02.classList.remove("active");
    formProgress01.classList.add("active");
  });

  // ステップ3の「戻る」の処理
  formButtonReverse03.addEventListener("click", () => {
    formStep03.classList.remove("active");
    formStep02.classList.add("active");
    formProgress03.classList.remove("active");
    formProgress02.classList.add("active");
  });

  // ステップ4の「戻る」の処理
  formButtonReverse04.addEventListener("click", () => {
    formStep04.classList.remove("active");
    formStep03.classList.add("active");
  });

  // 送信の処理
  formButtonSend.addEventListener("click", (elm) => {
    elm.target.setAttribute("disabled", "");
    elm.target.innerText = "送信中です";

    // 送信内容の定義
    const userPrefecture = formConfirmPrefectureStep.innerHTML;
    const userSex = formConfirmSexStep.innerHTML;
    const userName = formConfirmNameStep.innerHTML;
    const userAge = formConfirmAgeStep.innerHTML;
    const userTel = formConfirmTelStep.innerHTML;
    const userEmail = formConfirmMailStep.innerHTML;

    sendGas(userPrefecture, userAge, userName, userSex, userTel, userEmail);
    sendmail(userPrefecture, userAge, userName, userSex, userTel, userEmail);
  });
}

function mainForm() {
  // フォームの取得
  const contactMainForm = document.querySelector(".contact__form-main");
  const contactFormConfirm = document.querySelector(".contact__form-confirm");
  // 次へボタンの取得
  const nextButton = document.querySelector(".contact__next");
  // 戻るボタンの取得
  const prevButton = document.querySelector(".contact__prev");
  // 送信ボタンの取得
  const sendButton = document.querySelector(".contact__send");
  // フォームのvalue取得
  const formPrefecture = document.getElementsByName("form_prefecture")[0]; // 都道府県
  const formAge = document.getElementsByName("form_age")[0]; // 年齢
  const formYear = document.querySelector("select.year"); // 年
  const formMonth = document.querySelector("select.month"); // 月
  const formDay = document.querySelector("select.day"); // 日
  const formName = document.getElementsByName("form_name")[0]; // お名前
  const formSex = () => {
    let value = "";
    const radiobutton = Array.from(document.getElementsByName("form_sex"));

    radiobutton.forEach((elm) => {
      if (elm.checked) {
        value = elm.value;
      }
    });

    return value;
  }; // 性別
  const formTel = document.getElementsByName("form_tel")[0]; // 電話番号01
  const formMail = document.getElementsByName("form_email")[0]; // メールアドレス
  // エラーメッセージの管理
  const errorPrefecture = document.querySelector(".form-error--prefecture");
  const errorAge = document.querySelector(".form-error--age");
  const errorName = document.querySelector(".form-error--name");
  const errorSex = document.querySelector(".form-error--sex");
  const errorTel = document.querySelector(".form-error--tel");
  const errorEmail = document.querySelector(".form-error--mail");
  // 確認画面の入力
  const formConfirmPrefecture = document.getElementById(
    "form-confirm-prefecture"
  );
  const formConfirmAge = document.getElementById("form-confirm-age");
  const formConfirmName = document.getElementById("form-confirm-name");
  const formConfirmSex = document.getElementById("form-confirm-sex");
  const formConfirmTel = document.getElementById("form-confirm-tel");
  const formConfirmMail = document.getElementById("form-confirm-mail");

  // 生年月日のフォームに年月日を入れる
  const insertYear = () => {
    let date = new Date;
    let startYear = 1970;
    let endYear = date.getFullYear();

    for (let year = startYear; year <= endYear; year++) {
      const option = `<option value="${year}">${year}</option>`;

      formYear.insertAdjacentHTML('beforeend', option);
    }
  }

  const insertMonth = () => {
    for (let month = 1; month <= 12; month++) {
      const option = `<option value="${month}">${month}</option>`;

      formMonth.insertAdjacentHTML('beforeend', option);
    }
  }

  const insertDay = () => {
    for (let day = 1; day <= 31; day++) {
      const option = `<option value="${day}">${day}</option>`;

      formDay.insertAdjacentHTML('beforeend', option);
    }
  }

  insertYear();
  insertMonth();
  insertDay();

  nextButton.addEventListener("click", () => {
    // フォームの状態管理
    let prefectureCheck = false;
    let ageCheck = false;
    let nameCheck = false;
    let sexCheck = false;
    let telCheck = false;
    let mailCheck = false;

    // メールアドレスの正規表現
    const regExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // 都道府県の入力確認
    if (formPrefecture.value != "") {
      errorPrefecture.classList.remove("active");
      formConfirmPrefecture.innerHTML = formPrefecture.value;
      prefectureCheck = true;
    } else {
      errorPrefecture.classList.add("active");
      prefectureCheck = false;
    }

    // 名前の入力
    if (formName.value != "") {
      errorName.classList.remove("active");
      formConfirmName.innerHTML = formName.value;
      nameCheck = true;
    } else {
      errorName.classList.add("active");
      nameCheck = false;
    }

    // 年齢の入力確認
    if (formYear.value != '-' && formMonth.value != '-' && formDay.value != '-') {
      errorAge.classList.remove("active");
      formConfirmAge.innerHTML = `${formYear.value}年${formMonth.value}月${formDay.value}日`;
      ageCheck = true;
    } else {
      errorAge.classList.add("active");
      ageCheck = false;
    }

    // 性別の入力
    if (formSex() != "") {
      errorSex.classList.remove("active");
      formConfirmSex.innerHTML = formSex();
      sexCheck = true;
    } else {
      errorSex.classList.add("active");
      sexCheck = false;
    }

    // 電話の入力
    if (formTel.value != "") {
      errorTel.classList.remove("active");
      formConfirmTel.innerHTML = formTel.value;
      telCheck = true;
    } else {
      errorTel.classList.add("active");
      telCheck = false;
    }

    // メールアドレスのバリデーション
    if (formMail.value != "" && regExp.test(formMail.value)) {
      errorEmail.classList.remove("active");
      formConfirmMail.innerText = formMail.value;
      mailCheck = true;
    } else if (!regExp.test(formMail.value) && formMail.value != "") {
      errorEmail.innerHTML = "正しいメールアドレスを入力してください";
      errorEmail.classList.add("active");
      mailCheck = false;
    } else {
      errorEmail.innerHTML = "入力してください";
      errorEmail.classList.add("active");
      mailCheck = false;
    }

    if (
      prefectureCheck &&
      ageCheck &&
      nameCheck &&
      sexCheck &&
      telCheck &&
      mailCheck
    ) {
      contactMainForm.classList.remove("active");
      contactFormConfirm.classList.add("active");
    }
  });

  prevButton.addEventListener("click", () => {
    contactFormConfirm.classList.remove("active");
    contactMainForm.classList.add("active");
  });

  sendButton.addEventListener("click", (elm) => {
    elm.target.setAttribute("disabled", "");
    elm.target.innerText = "送信中です";

    // 送信内容の定義
    const userPrefecture = formConfirmPrefecture.innerHTML;
    const userAge = formConfirmAge.innerHTML;
    const userName = formConfirmName.innerHTML;
    const userSex = formConfirmSex.innerHTML;
    const userTel = formConfirmTel.innerHTML;
    const userEmail = formConfirmMail.innerHTML;

    sendGas(userPrefecture, userAge, userName, userSex, userTel, userEmail);
    sendmail(userPrefecture, userAge, userName, userSex, userTel, userEmail);
  });
}

function sendGas(user_prefecture, user_age, user_name, user_sex, user_tel, user_mail) {
  const protocol = location.protocol;
  const host = location.host;
  const path = location.pathname;
  const url = protocol + '//' + host + path + 'app/gas.php';

  $.ajax(url, {
    type: "post",
    data: {
      user_prefecture: user_prefecture,
      user_age: user_age,
      user_name: user_name,
      user_sex: user_sex,
      user_tel: user_tel,
      user_mail: user_mail,
    },
    dataType: "json",
  }).done((data) => {
    console.log(data.parameter);
  });
}

function sendmail(user_prefecture, user_age, user_name, user_sex, user_tel, user_mail) {
  const protocol = location.protocol;
  const host = location.host;
  const path = location.pathname;
  const url = protocol + '//' + host + path + 'app/sendmail.php';

  $.ajax(url, {
    type: "post",
    data: {
      user_prefecture: user_prefecture,
      user_age: user_age,
      user_name: user_name,
      user_sex: user_sex,
      user_tel: user_tel,
      user_mail: user_mail,
    },
    dataType: "text",
  }).done((data) => {
    window.location.href = protocol + '//' + host + path + 'thanks/';
  }).fail((data) => {
    alert("送信に失敗しました。時間をおいて再度送信をお願いいたします。");
    console.log(data);
  });
}

window.addEventListener("load", () => {
  storySlide();
  consultantSlide();
  faq();
  stepFormControl();
  mainForm();
});
